/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as $ from "jquery";
import Header from "./header";
import "./layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    // Scroll to content
    $(".cover .scroll-down").on("click", function(e) {
      $("html, body").animate({ scrollTop: $(".cover").height() }, 800);
      e.preventDefault();
    });

    // Scroll to top
    $(".site-footer .top-link").on("click", function(e) {
      $("html, body").animate({ scrollTop: 0 });
      e.preventDefault();
    });

    // Sidebar
    $(".sidebar-toggle, .overlay").on("click", function(e) {
      $("body").toggleClass("sidebar-opened");
      e.preventDefault();
    });
  }, []);

  return (
    <div className="home-template">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main className="site-main" role="main">
          <div className="site-content inner-wide">{children}</div>
        </main>
        <div className="sidebar">
          <div className="sidebar-scrollable">
            <div className="widget-area">
              <nav className="site-navigation" role="navigation">
                <h2 className="screen-reader-text">Explore Me</h2>
                <ul className="menu">
                  <li className="menu-item home" role="presentation">
                    <Link to="/">HOME</Link>
                  </li>
                  <li className="menu-item my-work" role="presentation">
                    <Link to="/mywork/">MY WORK</Link>
                  </li>
                  <li className="menu-item hobby-projects" role="presentation">
                    <Link to="/hobbyprojects/">HOBBY PROJECTS</Link>
                  </li>
                  <li className="menu-item about" role="presentation">
                    <Link to="/about/">ABOUT</Link>
                  </li>
                </ul>
              </nav>

              <aside className="widget widget-text">
                <h2 className="widget-title">About Wenbo Li</h2>
                <p>
                  I am an enthusiastic full stack developer who have proficiency
                  from front-end development to back-end data access, and
                  architecture design.
                </p>
                <p>
                  I'm often called a full stack, but I could build websites,
                  mobile apps and solutions with javascript only. Therefore, I'm
                  often called a javascript developer. When I am not writing
                  javascript, you properly find me write C#.
                </p>
                <p>
                  I am an creative thinker who is able to solve the complicated
                  problem in very creative but simple way. I care about the code
                  I wrote, but I prefer focus on the solution rather than the
                  implementation.
                </p>
                <p>
                  I love to try new things, new technologies and get out of the
                  comfort zone. I have many projects under my github account to
                  keep me motivated.
                </p>
              </aside>

              <aside className="widget widget-text">
                <h2 className="widget-title">Contact</h2>
                <p>
                  I like meet thoughtful people and make new friends, so if you
                  want to get hold of me, my contact details are followed.{" "}
                </p>
                <ul className="contacts">
                  <li>
                    <span
                      className="icon icon-envelope"
                      aria-hidden="true"
                    ></span>{" "}
                    wenbolovesnz@gmail.com
                  </li>
                </ul>
              </aside>
            </div>
            <a className="sidebar-toggle">
              <span className="screen-reader-text">Close Sidebar</span>
              <span className="icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>
        <div className="footer-widgets">
          <div className="inner-wide">
            <div className="widget-area">
              <div className="footer-col first">
                <aside className="widget widget-recent-posts">
                  <h2 className="widget-title">Latest Posts</h2>
                  <div className="recent-posts"></div>
                </aside>
              </div>
              <div className="footer-col last">
                <aside className="widget widget-tags">
                  <h2 className="widget-title">Tags</h2>
                  <div className="tagcloud"></div>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <footer className="site-footer">
          <div className="footer-top">
            <div className="inner-wide">
              <div className="offsite-links">
                <a
                  href="https://www.facebook.com/wenbo.li.31"
                  className="icon-facebook"
                >
                  <span className="screen-reader-text">Facebook</span>
                </a>
                <a
                  href="https://nz.linkedin.com/in/wenbo-li-97196055"
                  className="icon-linkedin"
                >
                  <span className="screen-reader-text">Linkedin</span>
                </a>
                <a
                  href="https://github.com/wenbolovesnz"
                  className="icon-github"
                >
                  <span className="screen-reader-text">GitHub</span>
                </a>
                <a
                  href="http://instagram.com/wenbolovesnz"
                  className="icon-instagram"
                >
                  <span className="screen-reader-text">Instagram</span>
                </a>
              </div>
              <a
                href="#page"
                className="top-link square icon-chevron-up"
                title="Back to Top"
              >
                <span className="screen-reader-text">Back to the top</span>
              </a>
            </div>
          </div>
          <div className="site-info inner-wide">
            <p>
              © <Link to="/">Wenbo Li </Link> all rights reserved.
            </p>
            <p>
              Built with{" "}
              <a target="_blank" href="http://www.Gatsby.org">
                Gatsby
              </a>
              .
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
