import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
  <header className="site-header">
    <div className="inner-wide">
      <h1 className="site-title">
        <Link to="/">{siteTitle}</Link>
      </h1>
      <nav className="site-navigation" role="navigation">
        <h2 className="screen-reader-text">Explore Website</h2>
        <ul className="menu">
          <li className="menu-item home" role="presentation">
            <Link to="/">HOME</Link>
          </li>
          <li className="menu-item my-work" role="presentation">
            <Link to="/mywork">MY WORK</Link>
          </li>
          <li className="menu-item hobby-projects" role="presentation">
            <Link to="/hobbyprojects">HOBBY PROJECTS</Link>
          </li>
          <li className="menu-item about" role="presentation">
            <Link to="/about">ABOUT</Link>
          </li>
        </ul>
      </nav>
      <a className="sidebar-toggle">
        <span className="screen-reader-text">Open Sidebar</span>
        <span className="icon" aria-hidden="true"></span>
      </a>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
