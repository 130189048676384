import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import HeroCover from "../components/hero-cover";
import PostItem from "../components/post-item";
import Pager from "../components/pager";

const IndexPage = ({ data: { allContentfulPost } }) => (
  <Layout>
    <SEO title="Home" />
    <HeroCover></HeroCover>
    <div className="post-grid">
      {allContentfulPost.edges.map(({ node }, index) => {
        return (
          <PostItem
            key={index}
            title={node.title}
            slug={node.slug}
            author={node.author}
            fluid={node.image.fluid}
            createdAt={node.createdAt}
          />
        );
      })}
    </div>
    <Pager />
  </Layout>
);

export default IndexPage;
export const query = graphql`
  {
    allContentfulPost {
      totalCount
      edges {
        node {
          title
          subtitle
          slug
          author
          createdAt
          image {
            fluid {
              srcWebp
            }
          }
        }
      }
    }
  }
`;
