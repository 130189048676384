import React from "react";
import { StaticQuery, graphql } from "gatsby";
const HeroCover = () => (
  <StaticQuery
    query={graphql`
      query {
        cover: file(relativePath: { eq: "cover.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="cover hero">
          <div
            className="cover-bg"
            style={{
              backgroundImage: `url(${data.cover.childImageSharp.fluid.srcWebp})`
            }}
          ></div>
          <div className="cover-content">
            <div className="inner">
              <div className="hero-wrap">
                <h2 className="hero-title fade-in">Wenbo Li</h2>
                <p className="hero-text fade-in">
                  Developer, architect, thinker, husband, father and a​ football
                  fan.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default HeroCover;
