import React from "react";
import { Link } from "gatsby";

const Pager = () => {
  return (
    <nav className="pagination" role="navigation">
      {/* <span className="page-number">Page 1 of 1</span> */}
      <div className="read-more">
        <Link className="button" to="/allposts" title="View more posts">
          Read All Posts
        </Link>
      </div>
    </nav>
  );
};

export default Pager;
