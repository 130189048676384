import React from "react";
import * as moment from "moment";

const PostItem = ({ fluid, title, slug, author, createdAt }) => {
  return (
    <article className="grid-item post">
      <div className="post-inside">
        <div
          className="post-bg"
          style={{
            backgroundImage: `url(http:${fluid.srcWebp})`
          }}
        ></div>
        <header className="post-header">
          <h2 className="post-title">
            <a href={`/posts/${slug}/`} rel="bookmark">
              {title}
            </a>
          </h2>
          <div className="post-meta">
            by{" "}
            <span className="post-author">
              <a href="/author/wenbo/">{author}</a>
            </span>{" "}
            on{" "}
            <time
              className="published"
              date={moment(createdAt).format("DD/MM/YYYY")}
            >
              {moment(createdAt).format("DD/MM/YYYY")}
            </time>
          </div>
        </header>
        <div className="read-more">
          <a className="button light" href={`/posts/${slug}/`} title={title}>
            Read More
          </a>
        </div>
      </div>
    </article>
  );
};

export default PostItem;
